
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Auth } from 'aws-amplify';
import { ElNotification } from "element-plus";
import store from "@/store";
import router from "../.././router";



export default defineComponent({
  name: "password-reset",
  
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data(){
    return{
      email:"",
      loading:false
    }
  },
  methods:{
    async SubmitForgotPassword(){
     Auth.forgotPassword(this.email)
    .then(data => console.log(data))
    .catch(err => console.log(err));
    },
    onSubmitForgotPassword(){
    this.loading=true

     store
          .dispatch(Actions.FORGOT_PASSWORD, this.email)
          .then(() => {
              this.loading=false;

            // Swal.fire({
            //   text: "Please check your mail to reset your password",
            //   icon: "success",
            //   buttonsStyling: false,
            //   confirmButtonText: "Ok",
            //   customClass: {
            //     confirmButton: "btn fw-bold btn-light-primary",
            //   },
            // }).then(function () {
            //   // Go to page after successfully login
            //   router.push({ name: "forgot-password" });
            // });
              ElNotification({
            title: 'Success',
            message: 'Please check your mail to reset your password',
            type: 'success',
            duration: 2000,
            position: 'top-right'
          })
          router.push({ name: "forgot-password" });

          })
          .catch((error) => {
          this.loading=false;
           ElNotification({
            title: 'Error',
            message: store.getters.getErrors[0],
            type: 'error',
            duration: 2000,
            position: 'top-right'
          })
          });

    }

  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    //Form submit function
    // const onSubmitForgotPassword = (values) => {
    //   // Activate loading indicator
    //   submitButton.value?.setAttribute("data-kt-indicator", "on");


    //   // dummy delay
    //   setTimeout(() => {
    //     // Send login request
    //     store
    //       .dispatch(Actions.FORGOT_PASSWORD, values)
    //       .then(() => {
    //         Swal.fire({
    //           text: "Please check your mail to reset your password",
    //           icon: "success",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok",
    //           customClass: {
    //             confirmButton: "btn fw-bold btn-light-primary",
    //           },
    //         }).then(function () {
    //           // Go to page after successfully login
    //           router.push({ name: "forgot-password" });
    //         });
    //       })
    //       .catch(() => {
    //         // Alert then login failed
    //         Swal.fire({
    //           text: store.getters.getErrors[0],
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Try again!",
    //           customClass: {
    //             confirmButton: "btn fw-bold btn-light-danger",
    //           },
    //         });
    //       });

    //     submitButton.value?.removeAttribute("data-kt-indicator");
    //   }, 2000);
    // };

    return {
      // onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
